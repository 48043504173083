import { Box, BoxProps } from '@chakra-ui/react';

type Props = {
  children: JSX.Element | JSX.Element[] | string;
  verticalPadding?: 'none' | 'sm' | 'md' | 'lg';
  noHorizontalPadding?: boolean;
} & BoxProps;

export function LayoutContainer({
  children,
  verticalPadding = 'lg',
  noHorizontalPadding,
  ...rest
}: Props): JSX.Element {
  let py = {};

  switch (verticalPadding) {
    case 'none':
      py = 0;
      break;
    case 'sm':
      py = { base: 2, md: 12, lg: 14 };
      break;
    case 'md':
      py = { base: 12, md: 20, lg: 24 };
      break;
    case 'lg':
      py = { base: 20, md: 28, lg: 36 };
      break;
  }

  return (
    <Box {...rest}>
      <Box
        maxW="container.xl"
        mx="auto"
        px={!noHorizontalPadding ? { base: 6, lg: 8 } : 0}
        py={py}
      >
        {children}
      </Box>
    </Box>
  );
}
