export function GatsbyIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#663399"
      />
      <path
        d="M3.54244 12.4571C2.34244 11.2571 1.71387 9.65712 1.71387 8.11426L7.94244 14.2857C6.34244 14.2285 4.74244 13.6571 3.54244 12.4571Z"
        fill="white"
      />
      <path
        d="M9.37145 14.1139L1.88574 6.62815C2.51431 3.82815 5.0286 1.71387 8.00003 1.71387C10.1143 1.71387 11.9429 2.74244 13.0857 4.2853L12.2286 5.02815C11.2572 3.71387 9.71431 2.85672 8.00003 2.85672C5.77146 2.85672 3.88574 4.2853 3.14288 6.2853L9.71431 12.8567C11.3715 12.2853 12.6286 10.8567 13.0286 9.14244H10.2857V7.99958H14.2857C14.2857 10.971 12.1715 13.4853 9.37145 14.1139Z"
        fill="white"
      />
    </svg>
  );
}
