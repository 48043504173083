/* eslint-disable */
import Image from 'next/legacy/image';
import {
  Box,
  Heading,
  Stack,
  Text,
  Center,
  HStack,
  chakra,
  useMediaQuery,
  Flex,
  Grid,
  GridItem
} from '@chakra-ui/react';
import { useState } from 'react';
import { motion, isValidMotionProp, useAnimation } from 'framer-motion';
import { CShapeBlurPattern, LayoutContainer } from '@components/common';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children'
});

type Quote = {
  name: string;
  header: string;
  content: string;
  role: string;
  logo: string;
  avatar: string;
};

const quotes = [
  {
    name: 'Guillermo Rauch',
    header: 'Really good stuff.',
    content:
      'The Next.js integration story is already really quite good and the Clerk team has really good ideas on how to make it even better and more seamless.',
    role: 'CEO',
    logo: '/images/logos/vercel-logo.svg',
    avatar: '/avatars/guillermo.jpeg'
  },
  {
    name: 'Sam Bhagwat',
    header: 'Easy to use.',
    content: `Clerk's drop-in components make it straightforward to add authentication to your Gatsby site.`,
    role: 'Gatsby Cofounder',
    avatar: '/avatars/sam.webp',
    logo: '/images/logos/gatsby-logo.svg'
  },
  {
    name: 'Mathias Lafeldt',
    header: 'Managed to integrate',
    content: `AppSync with 
      @ClerkDev
       over the weekend. Implementing a Lambda authorizer with Clerk's Go SDK was super easy.`,
    role: 'Grafbase Founder',
    avatar: '/avatars/mathias-lafeldt.jpeg',
    logo: '/images/logos/grafbase-logo.svg'
  },
  {
    name: 'Paul Copplestone',
    header: `Clerk's integration`,
    content: `gives Supabase developers another incredible option for handling authentication. And the Clerk team are a pleasure to work with.`,
    role: 'Supabase Cofounder',
    avatar: '/avatars/paul-copplestone.jpeg',
    logo: '/images/logos/supabase-logo.svg'
  }
];

export function Testimonials(): JSX.Element {
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');

  const [count, setCount] = useState<number>(0),
    [activeQuote, setActiveQuote] = useState<Quote>(quotes[3]),
    [quoteCleared, setQuoteCleared] = useState<boolean>(false);

  const controls = useAnimation();

  const segmentBig = (quote: Quote) => (
    <svg width={'40px'} height="2" viewBox={`0 0 40 2`} fill="none">
      <rect
        width={'40px'}
        height="2"
        rx="1"
        fill="white"
        fillOpacity={quote.name == activeQuote.name ? '.5' : '1'}
      />
    </svg>
  );

  const segmentSmall = (quote: Quote) => (
    <svg width={'56.75px'} height="2" viewBox={`0 0 56.75 2`} fill="none">
      <rect
        width={'56.75px'}
        height="2"
        rx="1"
        fill="white"
        fillOpacity={quote.name == activeQuote.name ? '.5' : '1'}
      />
    </svg>
  );

  const startAnimation = () => {
    controls.start({
      opacity: [0, 1, 1, 1, 0, 0],
      transition: {
        duration: 6,
        type: 'tween',
        ease: 'easeOut'
      }
    });
  };

  const handleOnAnimationEnd = () => {
    setCount(count < 3 ? count + 1 : 0);
    setQuoteCleared(false);
    setActiveQuote(quotes[count]);
  };

  startAnimation();
  return (
    <LayoutContainer verticalPadding="md">
      <Stack pos="relative" zIndex={10}>
        <Box
          pos="relative"
          display="flex"
          flexDir="row"
          justifyContent="center"
        >
          {/* Decoration */}
          <Box display={{ base: 'none', lg: 'flex' }} mt={60} mr={12}>
            <Box height="100%" width="120px" pos="relative">
              <Image
                src="/images/home/decoration-3.svg"
                alt="decoration"
                layout="fill"
              />
            </Box>
          </Box>

          <Center w="full" maxW="784px">
            <Box pos="relative" display="flex" flexDir="column" w="full">
              <Box paddingY={{ base: '20px', md: 0 }} w={{ md: '784px' }}>
                <Heading size="md" color="primary.500" mb={4} ml={1}>
                  Testimonials
                </Heading>
                <Heading size="h2">Trusted by startups and</Heading>
                <Heading mb={{ base: 4, md: 16 }} size="h2">
                  the world's largest companies
                </Heading>
              </Box>

              {/* Quotes section */}
              <Flex
                maxW={{ base: '100vw', md: '784px' }}
                height={{ base: '624px', md: '424px' }}
                borderRadius={'xl'}
                p={10}
                paddingBottom="50px"
                position="relative"
                overflow={{ md: 'hidden' }}
                justify={{ base: 'center', md: 'normal' }}
                bgGradient="linear(to-br, #E8E4FF 0%, white 40%, #E8E4FF 100%)"
              >
                {!quoteCleared && (
                  <ChakraBox
                    id="element"
                    w="full"
                    as={motion.div}
                    animate={controls}
                    onAnimationComplete={() => {
                      setQuoteCleared(true);
                      handleOnAnimationEnd();
                    }}
                  >
                    <Heading
                      size="h5"
                      fontSize={{ base: '20px', md: '24px' }}
                      mb={4}
                    >
                      “{activeQuote.header}
                    </Heading>

                    <Text textStyle="paragraph-lg" maxW="394px">
                      {activeQuote.content}”
                    </Text>

                    <HStack marginTop={10} w="394px" spacing={5}>
                      <Image
                        src={activeQuote.avatar}
                        alt="avatar"
                        height="50"
                        width="50"
                        className="rounded-full"
                      />

                      <Flex flexDir="column" marginTop={2} maxW="394px">
                        <Text textStyle="label-md" color="gray.700">
                          {activeQuote.name}
                        </Text>

                        <Text textStyle="paragraph-sm" color="gray.500">
                          {activeQuote.role}
                        </Text>
                      </Flex>
                    </HStack>
                  </ChakraBox>
                )}

                {/* <Text textStyle="label-sm" mt={10}>
                  Read all case studies
                </Text> */}
              </Flex>

              {/* Logo display */}
              <Box
                h={{ base: '136px', md: '472px' }}
                w={{ base: '80vw', sm: '60vw', md: '262px' }}
                pos="absolute"
                left={{ base: '50%', md: '620px' }}
                transform="translate(-50%, -50%)"
                right={{ base: '60px' }}
                top={{ base: '80%', md: '420px' }}
                bg="black"
                borderRadius="2xl"
                my={{ base: 12, md: 0 }}
              >
                {!quoteCleared && (
                  <Center>
                    <ChakraBox
                      animate={controls}
                      height={isSmallerThan768 ? '130px' : '426px'}
                      width="150px"
                      pos="relative"
                    >
                      <Image src={activeQuote.logo} alt="" layout="fill" />
                    </ChakraBox>
                  </Center>
                )}
                <Flex
                  justifyContent={{
                    base: 'center',
                    sm: 'space-evenly',
                    md: 'center'
                  }}
                >
                  <Grid
                    templateColumns={'repeat(4, 1fr)'}
                    paddingTop={{ lg: '24px' }}
                    marginTop={{ base: '-40px' }}
                    gap={2}
                  >
                    {quotes.map((quote, index) => (
                      <GridItem
                        onClick={() => {
                          setCount(index < 3 ? index + 1 : 0);
                          setActiveQuote(quotes[index]);
                        }}
                        cursor="pointer"
                        key={index}
                        display="flex"
                        justifyContent={{
                          base: 'space-between',
                          md: 'flex-end'
                        }}
                        py={4}
                        alignItems="center"
                      >
                        {isSmallerThan768
                          ? segmentSmall(quote)
                          : segmentBig(quote)}
                      </GridItem>
                    ))}
                  </Grid>
                </Flex>
              </Box>
            </Box>
          </Center>

          <Box display={{ base: 'none', lg: 'flex' }} mt={60} ml={12}>
            <Box height="100%" width="56px" pos="relative">
              <Image
                src="/images/home/decoration-4.svg"
                alt="decoration"
                layout="fill"
              />
            </Box>
          </Box>
        </Box>
      </Stack>
    </LayoutContainer>
  );
}
