export function FirebaseIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <mask
        id="mask0_20783_31600"
        maskUnits="userSpaceOnUse"
        x="2"
        y="0"
        width="12"
        height="16"
      >
        <path
          d="M12.2789 3.5736C12.2562 3.43886 12.1602 3.32791 12.0297 3.28585C11.8992 3.2438 11.7561 3.27764 11.6586 3.37362L9.77017 5.27348L8.27404 2.44641C8.21405 2.32532 8.09027 2.24867 7.95475 2.24867C7.81922 2.24867 7.69545 2.32532 7.63545 2.44641L6.82353 3.99175L4.77092 0.192024C4.60671 -0.117045 4.14145 -0.0352329 4.08672 0.310197L2.11621 12.9093L7.39826 15.8636C7.73065 16.0455 8.13323 16.0455 8.46562 15.8636L13.7933 12.9093L12.2789 3.5736Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_20783_31600)">
        <path
          d="M12.2789 3.5736C12.2562 3.43886 12.1602 3.32791 12.0297 3.28585C11.8992 3.2438 11.7561 3.27764 11.6586 3.37362L9.77017 5.27348L8.27404 2.44641C8.21405 2.32532 8.09027 2.24867 7.95475 2.24867C7.81922 2.24867 7.69545 2.32532 7.63545 2.44641L6.82353 3.99175L4.77092 0.192024C4.60671 -0.117045 4.14145 -0.0352329 4.08672 0.310197L2.11621 12.9093L7.39826 15.8636C7.73065 16.0455 8.13323 16.0455 8.46562 15.8636L13.7933 12.9093L12.2789 3.5736Z"
          fill="url(#paint0_linear_20783_31600)"
        />
        <path
          d="M2.11621 12.9093L4.09584 0.310197C4.15058 -0.0352329 4.61583 -0.117045 4.78004 0.192024L6.82353 3.99175L7.63545 2.44641C7.69822 2.32595 7.8231 2.25035 7.95931 2.25035C8.09552 2.25035 8.2204 2.32595 8.28317 2.44641L13.7933 12.9093H2.11621Z"
          fill="#FFA000"
        />
        <rect
          x="1.38672"
          y="3.18262"
          width="7.93676"
          height="10.0902"
          fill="url(#pattern0)"
        />
        <path
          d="M8.94 8.00002L6.81441 3.99121L2.11621 12.9088L8.94 8.00002Z"
          fill="#F57C00"
        />
        <rect
          x="1.75098"
          y="2.45508"
          width="12.7718"
          height="13.999"
          fill="url(#pattern1)"
        />
        <path
          d="M13.7933 12.9098L12.2789 3.57408C12.2562 3.43934 12.1602 3.32839 12.0297 3.28634C11.8992 3.24428 11.7561 3.27812 11.6586 3.3741L2.11621 12.9098L7.39826 15.8641C7.73065 16.0459 8.13323 16.0459 8.46562 15.8641L13.7933 12.9098Z"
          fill="#FFCA28"
        />
        <path
          d="M12.2792 3.5736C12.2566 3.43886 12.1605 3.32791 12.03 3.28585C11.8996 3.2438 11.7565 3.27764 11.6589 3.37361L9.7705 5.27348L8.27438 2.44641C8.21438 2.32532 8.0906 2.24867 7.95508 2.24867C7.81956 2.24867 7.69578 2.32532 7.63579 2.44641L6.82386 3.99175L4.77125 0.192024C4.60705 -0.117045 4.14179 -0.0352329 4.08705 0.310197L2.11654 12.9093H2.10742L2.11654 12.9184L2.17128 12.9457L9.76138 5.36438L11.6589 3.47361C11.7565 3.37763 11.8996 3.34379 12.03 3.38585C12.1605 3.4279 12.2566 3.53885 12.2792 3.67359L13.7754 12.9184L13.7936 12.9093L12.2792 3.5736ZM2.13479 12.8911L4.09617 0.4011C4.15091 0.0556697 4.61617 -0.0261426 4.78038 0.282926L6.82386 4.08265L7.63579 2.53731C7.69855 2.41685 7.82343 2.34125 7.95964 2.34125C8.09586 2.34125 8.22073 2.41685 8.2835 2.53731L9.74313 5.30075L2.13479 12.8911Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          opacity="0.2"
          d="M8.46562 15.7724C8.13323 15.9542 7.73065 15.9542 7.39826 15.7724L2.12533 12.8271L2.11621 12.909L7.39826 15.8633C7.73065 16.0451 8.13323 16.0451 8.46562 15.8633L13.7933 12.909L13.7842 12.8271L8.46562 15.7724Z"
          fill="#A52714"
        />
        <path
          d="M12.2789 3.5736C12.2562 3.43886 12.1602 3.32791 12.0297 3.28585C11.8992 3.2438 11.7561 3.27764 11.6586 3.37362L9.77017 5.27348L8.27404 2.44641C8.21405 2.32532 8.09027 2.24867 7.95475 2.24867C7.81922 2.24867 7.69545 2.32532 7.63545 2.44641L6.82353 3.99175L4.77092 0.192024C4.60671 -0.117045 4.14145 -0.0352329 4.08672 0.310197L2.11621 12.9093L7.39826 15.8636C7.73065 16.0455 8.13323 16.0455 8.46562 15.8636L13.7933 12.9093L12.2789 3.5736Z"
          fill="url(#paint1_linear_20783_31600)"
        />
      </g>
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use transform="scale(0.0114943 0.00892857)" />
        </pattern>
        <pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use transform="scale(0.00714286 0.00645161)" />
        </pattern>
        <linearGradient
          id="paint0_linear_20783_31600"
          x1="1.70964"
          y1="2.72427"
          x2="16.2396"
          y2="10.4359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20783_31600"
          x1="1.70964"
          y1="2.72427"
          x2="16.2396"
          y2="10.4359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <image id="image0_20783_31600" width="87" height="112" />
        <image id="image1_20783_31600" width="140" height="155" />
      </defs>
    </svg>
  );
}
