export function ExpoIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.58181 6.31707C7.71265 6.12418 7.85589 6.09965 7.97208 6.09965C8.08828 6.09965 8.28186 6.12418 8.41275 6.31707C9.44414 7.73386 11.1466 10.5562 12.4024 12.6381C13.2214 13.9958 13.8504 15.0386 13.9795 15.1713C14.4639 15.6694 15.1283 15.359 15.5144 14.7939C15.8945 14.2376 16 13.847 16 13.4302C16 13.1464 10.4935 2.90408 9.93889 2.05154C9.40558 1.23154 9.23187 1.02441 8.31909 1.02441H7.63604C6.72597 1.02441 6.59446 1.23154 6.0611 2.05154C5.50659 2.90408 0 13.1464 0 13.4302C0 13.847 0.105518 14.2376 0.485613 14.7939C0.871688 15.359 1.53609 15.6694 2.02053 15.1713C2.1496 15.0386 2.77862 13.9958 3.5976 12.6381C4.85342 10.5562 6.55042 7.73386 7.58181 6.31707Z"
        fill="black"
      />
    </svg>
  );
}
