import {
  ComponentDefaultProps,
  ComponentStyleConfig,
  SystemStyleObject
} from '@chakra-ui/react';

const linkStyles = {
  letterSpacing: 'normal',
  rounded: 'md',
  h: 'auto',
  p: 0
};

const transparentBorder = {
  border: '2px solid',
  borderColor: 'transparent'
};

const baseStyle: SystemStyleObject = {
  fontWeight: '500',
  letterSpacing: 'wide',
  py: 2,
  px: 5,
  _focusVisible: {
    boxShadow: 'focusRing.orange'
  },
  _hover: {
    color: 'white'
  }
};

const sizes = {
  sm: {
    fontSize: 'sm'
  },
  md: {
    fontSize: 'sm',
    rounded: 'lg'
  },
  lg: {
    fontSize: 'lg'
  }
};

const defaultProps: ComponentDefaultProps = {
  size: 'md'
};

const variants: SystemStyleObject = {
  ghost: {
    color: 'gray.700',

    _hover: {
      color: 'gray.700'
    },

    _active: {
      bg: 'gray.100'
    }
  },

  link: {
    ...linkStyles,

    _hover: {
      textDecoration: 'none',
      color: 'primary.700'
    }
  },

  base: {
    bg: 'primary.500',
    color: 'white',
    _hover: {
      bg: 'primary.700'
    }
  },

  'primary-outline': {
    borderWidth: '2px',
    borderColor: 'primary.500',
    bg: 'transparent',
    color: 'primary.500',

    _hover: {
      color: 'primary.500'
    }
  },

  white: {
    bg: 'white',
    color: 'primary.500',
    shadow: 'secondaryButtonNormal',

    _hover: {
      bg: 'gray.100',
      color: 'primary.700'
    }
  },

  blue: {
    bg: 'var(--primaryBlue)',
    color: 'white',

    _hover: {
      bg: 'var(--primaryBlueHover)'
    }
  },

  gray: {
    bg: 'gray.800',
    color: 'white',

    _hover: {
      bg: 'gray.900'
    }
  },

  'gray-link': {
    ...linkStyles,
    color: 'gray.800',

    _hover: {
      color: 'gray.900'
    }
  },

  'dark-blue': {
    ...transparentBorder,
    bg: 'dark-blue.500',
    color: 'white',

    _hover: {
      bg: 'dark-blue.700'
    },
    _focus: {
      boxShadow: 'none'
    }
  },

  'dark-blue-outline': {
    color: 'dark-blue.500',
    border: '2px solid',
    borderColor: 'dark-blue.500',

    _hover: {
      color: 'dark-blue.500'
    }
  },

  'dark-blue-ghost': {
    ...transparentBorder,
    color: 'dark-blue.500',

    _hover: {
      bg: 'dark-blue.100',
      color: 'dark-blue.800'
    }
  },

  'dark-blue-link': {
    ...linkStyles,
    color: 'dark-blue.500',

    _hover: {
      color: 'dark-blue.900'
    }
  }
};

export const Button: ComponentStyleConfig = {
  baseStyle,
  sizes,
  defaultProps,
  variants
};
