import Link from 'next/link';
import { Text } from '@chakra-ui/react';

type Props = {
  label: string;
  href: string;
};

export function NavItemTextOnly({ label, href }: Props): JSX.Element {
  return (
    <Link legacyBehavior href={href} passHref>
      <Text as="a" textStyle="paragraph-sm" color="gray.700" py={2}>
        {label}
      </Text>
    </Link>
  );
}
