import React from 'react';
import { useRouter } from 'next/router';

type Args = {
  closeMenu: () => void;
};

export function useCloseMenuOnNavigate({ closeMenu }: Args) {
  const router = useRouter();

  React.useEffect(() => {
    const handleRouteChange = () => {
      closeMenu();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('hashChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
