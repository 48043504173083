import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { useCloseMenuOnNavigate } from './use-close-menu-on-navigate';

let pending = false;

export default function Hover({ Button, popup }) {
  let timeout;
  const timeoutDuration = 100;

  const [openState, setOpenState] = useState(false);

  useCloseMenuOnNavigate({ closeMenu: () => setOpenState(false) });

  const onMouseEnter = () => {
    pending = true;
    timeout = setTimeout(() => {
      pending = false;
      setOpenState(true);
    }, timeoutDuration);
  };

  const onMouseLeave = () => {
    if (pending) {
      clearTimeout(timeout);
    }
    setOpenState(false);
  };

  return (
    <div className="relative">
      <div
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
      >
        <Button open={openState} />
        <Transition
          show={openState}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          {popup}
        </Transition>
      </div>
    </div>
  );
}
