import { Heading } from '@chakra-ui/react';

type Props = {
  children: string;
};

export function SectionTitle({ children }: Props): JSX.Element {
  return (
    <Heading as="h3" size="sm" color="gray.500" p={3}>
      {children}
    </Heading>
  );
}
