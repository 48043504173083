import { createContext, useContext, useEffect, useState } from 'react';

const RudderStackContext = createContext({
  isLoaded: false,
  setIsLoaded: () => {}
});

export const useRudderStack = () => useContext(RudderStackContext);

export const RudderStackProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    async function initializeRudder() {
      const rudderanalytics = await import('rudder-sdk-js');
      rudderanalytics.load(
        `${process.env.NEXT_PUBLIC_RUDDERSTACK}`,
        'https://clerkshehjzyk.dataplane.rudderstack.com',
        {
          integrations: { All: true }
        }
      );

      rudderanalytics.ready(() => {
        setIsLoaded(true);
      });
    }

    initializeRudder();
  }, []);

  return (
    <RudderStackContext.Provider value={{ isLoaded, setIsLoaded }}>
      {children}
    </RudderStackContext.Provider>
  );
};
