import NextLink from 'next/link';
import { Box, Flex, Link, Stack, Text } from '@chakra-ui/react';
import { MegaMenuPopover } from './popover';
import { SectionTitle } from './section-title';
import { NavItemTextOnly } from './nav-item-text-only';

const company = [
  {
    label: 'About Us',
    href: '#'
  },
  {
    label: 'Blog',
    href: '#'
  },
  {
    label: 'Open Source',
    href: '#'
  },
  {
    label: 'Privacy Trust Center',
    href: '#'
  },
  {
    label: 'Careers (we’re hiring!)',
    href: '#'
  }
];

const blog = [
  {
    label: 'Refactoring Stripe’s API for frontend access',
    href: '#'
  },
  {
    label:
      'Unicorn or Chameleon? Two strategies for exporting customizable React components',
    href: '#'
  }
];

const Divider = () => <Box bg="gray.100" w="1px" />;

export function Company(): JSX.Element {
  return (
    <MegaMenuPopover label="Company">
      <Flex gap={4} w="940px">
        <Stack flex={1}>
          <SectionTitle>Why Clerk?</SectionTitle>

          <Flex p={3} h="full">
            <Flex bg="gray.25" flex={1} rounded="lg" p={3}></Flex>
          </Flex>
        </Stack>

        <Divider />

        <Box flex={1}>
          <SectionTitle>Company</SectionTitle>

          <Stack px={3} spacing={0}>
            {company.map(({ label, href }) => (
              <NavItemTextOnly key={label} label={label} href={href} />
            ))}
          </Stack>
        </Box>

        <Divider />

        <Stack flex={1.9} spacing={0}>
          <SectionTitle>From the blog</SectionTitle>

          <Stack px={3} spacing={0}>
            {blog.map(({ label, href }) => (
              <Text key={label} textStyle="label-sm" color="gray.700" py={2}>
                {label}

                <NextLink legacyBehavior href={href} passHref>
                  <Link
                    ml={1}
                    borderBottom="1px solid"
                    borderColor="primary.500"
                  >
                    link
                  </Link>
                </NextLink>
              </Text>
            ))}
          </Stack>

          <Flex p={3} h="full">
            <Flex bg="gray.25" flex={1} rounded="lg" p={3}></Flex>
          </Flex>
        </Stack>
      </Flex>
    </MegaMenuPopover>
  );
}
