export function GearIcon(props) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.89615 1.09776C8.54081 -0.365919 6.45919 -0.36592 6.10386 1.09776C5.87431 2.04327 4.79105 2.49198 3.96015 1.98571C2.67392 1.20199 1.20199 2.67392 1.98571 3.96015C2.49198 4.79105 2.04327 5.87431 1.09776 6.10386C-0.36592 6.45919 -0.365919 8.54081 1.09776 8.89614C2.04327 9.12569 2.49198 10.209 1.98571 11.0398C1.20199 12.3261 2.67392 13.798 3.96016 13.0143C4.79105 12.508 5.87431 12.9567 6.10386 13.9022C6.45919 15.3659 8.54081 15.3659 8.89615 13.9022C9.12569 12.9567 10.209 12.508 11.0398 13.0143C12.3261 13.798 13.798 12.3261 13.0143 11.0398C12.508 10.209 12.9567 9.12569 13.9022 8.89614C15.3659 8.54081 15.3659 6.45919 13.9022 6.10386C12.9567 5.87431 12.508 4.79105 13.0143 3.96015C13.798 2.67392 12.3261 1.20199 11.0398 1.98571C10.209 2.49198 9.12569 2.04327 8.89615 1.09776ZM7.5 10.3125C9.0533 10.3125 10.3125 9.0533 10.3125 7.5C10.3125 5.9467 9.0533 4.6875 7.5 4.6875C5.9467 4.6875 4.6875 5.9467 4.6875 7.5C4.6875 9.0533 5.9467 10.3125 7.5 10.3125Z"
        fill="currentColor"
      />
    </svg>
  );
}
