import {
  ChakraTheme,
  extendTheme,
  ThemeConfig,
  withDefaultColorScheme
} from '@chakra-ui/react';
import { mode, Styles } from '@chakra-ui/theme-tools';
import foundations, { colors } from './foundations';
import components from './components';

const { gray, primary } = colors;

const config: ThemeConfig = {
  initialColorMode: 'system'
};

const styles: Styles = {
  global: props => ({
    ':root': {
      '--bodyBg': mode('#FFF', '#1A1B1E')(props),
      '--cardBg': mode('#FFF', '#222225')(props),
      '--highlightBg': mode(gray[50], '#222225')(props),
      '--snippetBg': mode(gray[100], gray[700])(props),
      '--tableHead': mode(gray[50], '#202122')(props),
      '--tableBody': mode('#FFF', '#242528')(props),

      '--borderColor': mode(gray[200], '#3F3F42')(props),
      '--primaryTextColor': mode('#000', '#FFF')(props),
      '--secondaryTextColor': mode(gray[500], gray[400])(props),
      '--iconColor': mode(gray[400], '#5F5F61')(props),

      '--primaryBlue': mode(primary[500], '#7857FF')(props),
      '--primaryBlueHover': mode(primary[600], primary[500])(props),
      '--secondaryBlue': mode(primary[500], primary[400])(props)
    },
    body: {
      color: 'gray.700',
      bg: 'gray.25'
    },
    a: {
      color: 'primary.500'
    },
    div: {
      fontFeatureSettings:
        "'cv01' on, 'cv02' on, 'cv03' on, 'cv09' on, 'cv05' on, 'calt' off, 'liga' off, 'kern' off"
    }
  })
};

const themeOverrides: Partial<ChakraTheme> = {
  styles,
  config,
  components,
  ...foundations
};

const theme = extendTheme(
  themeOverrides,
  withDefaultColorScheme({ colorScheme: 'primary' })
);

export default theme;
