import React from 'react';
import { Box, CloseButton, Flex } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function MobileMenu({ isOpen, onClose }: Props): JSX.Element {
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!menuRef) return;

    if (isOpen) {
      disableBodyScroll(menuRef.current as HTMLDivElement);
    } else {
      enableBodyScroll(menuRef.current as HTMLDivElement);
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      <Box
        ref={menuRef}
        bg="blue"
        pos="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        overflow="auto"
        px={4}
        py={4}
        zIndex={isOpen ? 9999 : -9999}
        opacity={isOpen ? 1 : 0}
        transition="opacity .2s"
      >
        {isOpen && (
          <motion.div
            transition={{ duration: 0.1 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box bg="red">
              <Flex justify="flex-end">
                <CloseButton onClick={onClose} />
              </Flex>
              Mobile menu
            </Box>
          </motion.div>
        )}
      </Box>
    </AnimatePresence>
  );
}
