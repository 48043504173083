// This object should be spread into the component
export const containerGradient = {
  bg: 'linear-gradient(0deg, rgba(28, 15, 77, 0.12) -52.71%, rgba(28, 15, 77, 0) 85%),rgba(10, 12, 61, 1)',
  bgGradient:
    'radial-gradient(at 50% 100%, rgba(91, 197, 239, 0.1) 0%, rgba(255, 255, 255, 0) 80%, rgba(91, 197, 239, 0) 80%)'
};

export const colors = {
  // New branding
  'dark-text': '#B3B3D0',
  background: '#0C0129',

  primary: {
    '25': '#F2F0FF',
    '100': '#C4B5FF',
    '300': '#B6A3FF',
    '400': '#8370FF', // Custom (not delivered by the designer)
    '500': '#6C47FF',
    '700': '#4C32B3',
    '900': '#2B1C66',

    // TODO: remove placeholders below after new branding migration is finished; they're currently in place so old code doesn't break
    '200': '#6C47FF', // Placeholder
    '600': '#6C47FF', // Placeholder
    '800': '#6C47FF' // Placeholder
  },

  gray: {
    '25': '#F7F9FC',
    '50': '#F0F4F9',
    '100': '#E4E9F2',
    '200': '#D8DDE8',
    '300': '#C0C8D3',
    '400': '#949EB2',
    '500': '#626B7F',
    '600': '#394356',
    '700': '#262F40',
    '800': '#141D2E',
    '900': '#061023'
  },

  blue: {
    '25': '#E8F6FC',
    '100': '#BDE8F9',
    '300': '#8CD6F4',
    '500': '#5BC5EF',
    '700': '#408AA7',
    '900': '#244F60'
  },

  yellow: {
    '25': '#FFF8EB',
    '100': '#FDEECF',
    '300': '#FCE1AA',
    '500': '#FBD486',
    '700': '#B0945E',
    '900': '#645536'
  },

  green: {
    '25': '#F7FEEC',
    '100': '#E5FDC7',
    '300': '#D2FB9C',
    '500': '#BEF972',
    '700': '#85AE50',
    '900': '#4C642E'
  },

  red: {
    '25': '#FFF4F4',
    '100': '#FFD2D2',
    '300': '#FFB1B1',
    '500': '#FF8F8F',
    '700': '#B36464',
    '900': '#663939'
  },

  // Previous colours
  // gray: {
  //   '50': '#0000000a',
  //   '100': '#00000014',
  //   '200': '#CCCCCC',
  //   // '300': '#0000004d',
  //   '400': '#B3B3B3',
  //   '500': '#757575',
  //   // '600': '#666666',
  //   '700': '#4D4D4D',
  //   '800': '#3C4048',
  //   '900': '#282C34'
  // },
  // primary: {
  //   '50': '#e7e8fd',
  //   '100': '#e5ebff',
  //   '200': '#c2c5fa',
  //   '300': '#708cf5',
  //   '400': '#5274f3',
  //   '500': '#335bf1',
  //   '600': '#2e53ef',
  //   '700': '#2749ed',
  //   '800': '#0029d4',
  //   '900': '#001bc9'
  // },
  danger: {
    '50': '#ffe4e3',
    '100': '#ffe8e8',
    '200': '#ff8e8b',
    '300': '#ff615d',
    '400': '#ff3f3a',
    '500': '#ff1d17',
    '600': '#ff1a14',
    '700': '#ff1511',
    '800': '#ff110d',
    '900': '#ff0a07'
  },
  warning: {
    '50': '#fef8e3',
    '100': '#fdf2cb',
    '200': '#fae28c',
    '300': '#f8d65d',
    '400': '#f7cd3b',
    '500': '#f5c418',
    '600': '#f4be15',
    '700': '#f2b611',
    '800': '#f0af0e',
    '900': '#eea208'
  },
  success: {
    '50': '#e7f9e7',
    '100': '#ccefcc',
    '200': '#9be59b',
    '300': '#72da72',
    '400': '#54d254',
    '500': '#36ca36',
    '600': '#30c530',
    '700': '#29bd29',
    '800': '#22b722',
    '900': '#16ab16'
  },
  // Adding this temporarily for the needs of create new prod instance and will come back with Cooper's feedback
  orange: {
    '50': '#FFF3E0',
    '100': '#FFE0B2',
    '200': '#FFCC80',
    '300': '#FFB74D',
    '400': '#FFA726',
    '500': '#FF9800',
    '600': '#FB8C00',
    '700': '#F57C00',
    '800': '#EF6C00',
    '900': '#E65100'
  },
  'dark-blue': {
    '50': '#eaebf0',
    '100': '#cbd3f1',
    '200': '#a8aec2',
    '300': '#858daa',
    '400': '#6b7497',
    '500': '#515c85',
    '600': '#4a547d',
    '700': '#404a72',
    '800': '#374168',
    '900': '#273055'
  }
};
