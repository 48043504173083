import { ComponentSelectButtons, DescriptionBlock } from './page-components';
import React from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  Button,
  Heading,
  chakra
} from '@chakra-ui/react';
import { CodeWindow } from '@components/common';
import Image from 'next/image';
import { MapIcon, SparklesIcon } from '@heroicons/react/solid';
import { motion, isValidMotionProp, useAnimation } from 'framer-motion';
import { ThemeBGMobileLoader } from './theme-bg-mobile-loader';
import { ThemeLoader } from './theme-loader';
import { useRouter } from 'next/router';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children'
});

export function CustomizationPreviewMobile({
  themes,
  setCurrentTheme,
  currentTheme,
  snippet,
  header,
  subheader,
  icon
}: any): JSX.Element {
  const [showCode, setShowCode] = React.useState<boolean>(false);

  const controls = useAnimation();
  const { pathname } = useRouter();

  const handleOnClick = async (component: any) => {
    await controls.start({
      opacity: ['100%', '0%'],
      transition: {
        duration: 0.125,
        type: 'tween'
      }
    });
    await setCurrentTheme(component);
    await controls.start({
      opacity: ['0%', '100%'],
      transition: {
        duration: 0.125,
        type: 'tween'
      }
    });
  };

  return (
    <>
      <Stack
        pos="relative"
        flexDir="column"
        justifyContent={'center'}
        alignItems="center"
        color={pathname === '/' ? 'white' : 'inherit'}
      >
        <Box pos="relative" w="100%">
          <Flex
            justifyContent={'center'}
            flexDir="column"
            alignItems={'center'}
          >
            <Box>
              <Image src={icon} width={72} height={72} alt="" />
            </Box>
            <Heading
              as="h3"
              size="h3"
              pt="24px"
              pb="16px"
              textAlign={'center'}
              px={'16px'}
              maxW={{ base: '60%', sm: '100%' }}
              color={pathname === '/' ? 'white' : 'inherit'}
            >
              {header}
            </Heading>
            <Text
              textAlign={'center'}
              fontSize={'16px'}
              fontFamily={'heading'}
              pb="30px"
              maxW="90vw"
            >
              {subheader}
            </Text>
          </Flex>
        </Box>

        <Flex
          flexDir={'column'}
          w="full"
          justifyContent={'center'}
          alignItems="center"
        >
          <ComponentSelectButtons
            componentList={themes}
            currentComponent={currentTheme}
            handleOnClick={handleOnClick}
          />

          {showCode ? (
            <Box
              width={359}
              height={570}
              display="flex"
              justifyContent="center"
              alignItems={'center'}
            >
              <Box
                py="24px"
                pos="relative"
                justifySelf="center"
                width={320}
                height={493}
              >
                <CodeWindow snippet={snippet} />
                <Box
                  w={320}
                  h={100}
                  bg="transparent"
                  pos="relative"
                  top={-100}
                  rounded="xl"
                  bgGradient="linear(to-b, transparent 0%,  #0F0839 50%)"
                ></Box>
              </Box>
            </Box>
          ) : (
            <ChakraBox
              zIndex={10}
              id="component"
              as={motion.div}
              animate={controls}
              pt="24px"
              pos="relative"
              justifySelf="center"
            >
              <Box
                pos="relative"
                top={
                  currentTheme?.name === 'Retro' ||
                  currentTheme?.name === 'Neobrutalism' ||
                  currentTheme?.name === 'Yolo'
                    ? -3
                    : currentTheme?.name === 'Dark'
                    ? 4
                    : currentTheme?.name === 'Wave'
                    ? 4
                    : currentTheme?.name === 'Abstract'
                    ? 4
                    : 4
                }
                transform={
                  currentTheme?.name === 'Retro' ||
                  currentTheme?.name === 'Neobrutalism' ||
                  currentTheme?.name === 'Yolo'
                    ? 'scale(.9)'
                    : 'scale(1)'
                }
                width={359}
                height={546}
              >
                <ThemeBGMobileLoader currentTheme={currentTheme} />
              </Box>
              <Box
                zIndex={10}
                id="component"
                width={'full'}
                height={453}
                top={'16%'}
                right={currentTheme?.name == 'Neobrutalism' ? -2 : 0}
                pos="absolute"
                filter="drop-shadow(0px 2px 25px rgba(143, 143, 143, 0.5))"
              >
                <ThemeLoader currentTheme={currentTheme} />
              </Box>
            </ChakraBox>
          )}
          <Box
            pos="relative"
            display="flex"
            top={-830}
            justifyContent="center"
            w="full"
            alignContent="center"
          >
            {currentTheme?.name === 'Retro' ? (
              <Box
                zIndex={100}
                alignSelf="center"
                pos="absolute"
                top={700}
                onClick={() => {
                  setShowCode(!showCode);
                }}
                cursor="pointer"
              >
                <Image
                  src="/images/components/themes/retro-button.svg"
                  height={50}
                  width={159}
                  alt={currentTheme?.name}
                />
              </Box>
            ) : (
              <Button
                zIndex={100}
                rounded="full"
                alignSelf="center"
                py="24px"
                px="13px"
                height="20px"
                lineHeight={'24px'}
                fontWeight={700}
                pos="absolute"
                top={700}
                onClick={() => {
                  setShowCode(!showCode);
                }}
              >
                {showCode ? 'Hide code' : 'View code'}
              </Button>
            )}
          </Box>
        </Flex>
        <Flex
          w="full"
          justifyContent={'center'}
          alignItems="center"
          flexDir="column"
        >
          <DescriptionBlock
            name="Appearance"
            icon={SparklesIcon}
            description={`Modify the theming of the prebuilt UIs. Compatible with Tailwind
            CSS, Chakra UI, Styled Components, custom CSS, and more. Light,
            Retro, Yolo...we can handle it all.`}
          />

          <DescriptionBlock
            name="Localization"
            icon={MapIcon}
            description={`Override the default strings for any element to localize to any
            language or change the wording to match your brand.`}
          />
        </Flex>
      </Stack>
    </>
  );
}
