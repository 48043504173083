export function NextJSIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.76758 5.75217H6.7789V5.99037H4.04362V7.78317H6.61579V8.02137H4.04362V9.98969H6.81027V10.2279H3.76758V5.75217ZM7.04866 5.75217H7.36862L8.78645 7.72048L10.2356 5.75217L12.2068 3.25195L8.96838 7.92734L10.6372 10.2279H10.3047L8.78645 8.13421L7.26197 10.2279H6.93574L8.61706 7.92734L7.04866 5.75217ZM10.7564 5.99037V5.75217H14.188V5.99037H12.6071V10.2279H12.331V5.99037H10.7564Z"
        fill="black"
      />
      <path
        d="M0 5.75195H0.345047L5.10304 12.8379L3.13679 10.2277L0.288585 6.09045L0.276038 10.2277H0V5.75195Z"
        fill="black"
      />
      <path
        d="M14.1602 9.91822C14.2173 9.91822 14.2589 9.87473 14.2589 9.81853C14.2589 9.76233 14.2173 9.71884 14.1602 9.71884C14.1038 9.71884 14.0615 9.76233 14.0615 9.81853C14.0615 9.87473 14.1038 9.91822 14.1602 9.91822ZM14.4315 9.65594C14.4315 9.82187 14.5516 9.93026 14.7269 9.93026C14.9135 9.93026 15.0263 9.81853 15.0263 9.6245V8.94139H14.8759V9.62383C14.8759 9.73155 14.8215 9.78909 14.7255 9.78909C14.6396 9.78909 14.5812 9.73556 14.5792 9.65594H14.4315ZM15.223 9.64725C15.2338 9.81986 15.3781 9.93026 15.593 9.93026C15.8226 9.93026 15.9662 9.81451 15.9662 9.62985C15.9662 9.48466 15.8843 9.40438 15.6856 9.35821L15.5789 9.33212C15.4526 9.30268 15.4016 9.2632 15.4016 9.19429C15.4016 9.10731 15.4808 9.05044 15.5997 9.05044C15.7125 9.05044 15.7903 9.10598 15.8044 9.19496H15.9508C15.9421 9.03238 15.7984 8.91797 15.6017 8.91797C15.3902 8.91797 15.2492 9.03238 15.2492 9.20433C15.2492 9.34617 15.3291 9.43047 15.5043 9.47128L15.6292 9.50139C15.7574 9.5315 15.8138 9.57566 15.8138 9.64925C15.8138 9.73489 15.7252 9.79712 15.6044 9.79712C15.4748 9.79712 15.3848 9.73891 15.3721 9.64725H15.223Z"
        fill="black"
      />
    </svg>
  );
}
