import { useRouter } from 'next/router';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text
} from '@chakra-ui/react';
import { PURPLE_BG_PAGES } from '@lib';

type Props = {
  label: string;
  children: JSX.Element;
};

export function MegaMenuPopover({ label, children }: Props): JSX.Element {
  const { pathname } = useRouter();
  const hasPurpleBg = PURPLE_BG_PAGES.includes(pathname);
  const textColor = hasPurpleBg ? 'white' : 'gray.700';

  return (
    <Popover trigger="hover" placement="bottom" gutter={10}>
      <PopoverTrigger>
        <Text as="button" textStyle="label-sm" color={textColor}>
          {label}
        </Text>
      </PopoverTrigger>

      <PopoverContent w="fit-content">{children}</PopoverContent>
    </Popover>
  );
}
