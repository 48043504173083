export function FaunaIcon(props) {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.6882 10.0728C33.7187 11.0639 32.2889 12.8417 31.3148 15.6106C31.0633 16.3501 30.4348 17.1681 29.7279 17.7187L32.1632 20.3304L24.433 14.9184L3.09668 0.00390625C3.09668 0.00390625 4.63642 10.0097 5.17062 13.6912C5.5477 16.2871 6.19187 17.4513 8.23438 18.6313L9.05137 19.0718L12.5708 20.9282L10.4811 19.8426L20.128 25.113L20.0653 25.2547L9.67985 20.4404C10.2298 22.3284 11.2981 25.9626 11.7538 27.5674C12.2408 29.2978 12.7907 29.9273 14.4719 30.5407L17.5671 31.6735L19.4839 30.9184L17.0486 32.5388L4.87209 48.0039C12.9636 40.4837 19.8138 37.8092 24.8258 35.6224C31.2205 32.8534 35.0698 31.0757 37.5836 24.6883C39.3748 20.2045 40.7731 14.4621 42.5485 12.2438L46.3351 7.3982C46.3351 7.3982 38.495 9.47493 36.6882 10.0728Z"
        fill="#3F00A5"
      />
    </svg>
  );
}
