import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Center } from '@chakra-ui/react';
import { PURPLE_BG_PAGES, URLS } from '@lib';
import { Product } from './product';
import { Developers } from './developers';
import { Company } from './company';
import { Customers } from './customers';

export function MegaMenu(): JSX.Element {
  const { pathname } = useRouter();
  const hasPurpleBg = PURPLE_BG_PAGES.includes(pathname);
  const textColor = hasPurpleBg ? 'white' : 'gray.700';

  return (
    <Center gap={12} display={{ base: 'none', lg: 'flex' }}>
      <Product />
      <Developers />
      <Company />
      <Customers />

      <Link legacyBehavior href={URLS.pricing} passHref>
        <Box as="a" textStyle="label-sm" color={textColor}>
          Pricing
        </Box>
      </Link>
    </Center>
  );
}
