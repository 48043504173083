import React from 'react';
import { useSession, useUser } from '@clerk/nextjs';
import { Application, Instance } from '@types';

export const AppAndInstanceSwitcherContext = React.createContext<{
  apps: Application[] | null;
  currentApp: Application | null;
  setCurrentApp: (app: Application) => void;
  currentInstance: Instance | null;
  setCurrentInstance: (instance: Instance) => void;
}>({} as any);

type Props = {
  children: React.ReactNode;
};

export function AppAndInstanceSwitcherProvider({ children }: Props) {
  const { isLoaded, isSignedIn } = useUser();
  const { session } = useSession();

  const [apps, setApps] = React.useState<Application[] | null>(null);
  const [currentApp, setCurrentApp] = React.useState<Application | null>(null);
  const [currentInstance, setCurrentInstance] = React.useState<Instance | null>(
    null
  );

  const getApplications = React.useCallback(async () => {
    if (!session || !!apps) return;

    try {
      const token = await session?.getToken();

      if (!token) {
        throw new Error('No token found');
      }

      const res = await fetch(
        `${process.env.NEXT_PUBLIC_DAPI_URL}/v1/instance_keys`,
        {
          headers: {
            Authorization: token
          }
        }
      );

      if (!res.ok) {
        throw new Error('Failed to fetch applications');
      }

      const data: Application[] = await res.json();

      if (data && data.length > 0) {
        setApps(data);
        setCurrentApp(data[0]);
        setCurrentInstance(
          data[0].instances.find(
            ({ environment_type }) => environment_type === 'development'
          ) || data[0].instances[0]
        );
      }
    } catch (error) {
      console.log('Error getting applications', error);
    }
  }, [session, apps]);

  React.useEffect(() => {
    if (!isLoaded) return;

    if (isSignedIn && session) {
      getApplications();
    } else {
      setCurrentApp(null);
      setCurrentInstance(null);
    }
  }, [getApplications, isLoaded, isSignedIn, session]);

  const handleSetCurrentApp = (app: Application) => {
    setCurrentApp(app);
    setCurrentInstance(app.instances[0]);
  };

  return (
    <AppAndInstanceSwitcherContext.Provider
      value={{
        apps,
        currentApp,
        setCurrentApp: handleSetCurrentApp,
        currentInstance,
        setCurrentInstance
      }}
    >
      {children}
    </AppAndInstanceSwitcherContext.Provider>
  );
}
