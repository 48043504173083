export function HasuraIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5636 5.44801C16.0228 4.01395 15.7468 1.14979 14.8561 0.0930136C14.7399 -0.0452563 14.5212 -0.0255036 14.4246 0.126593L13.3271 1.853C13.0551 2.19867 12.5664 2.27768 12.1999 2.03669C11.0096 1.25645 9.58691 0.802141 8.05776 0.802141C6.52861 0.802141 5.10587 1.25645 3.91566 2.03669C3.54914 2.27768 3.06043 2.19669 2.78851 1.853L1.6909 0.126593C1.59434 -0.0255036 1.37561 -0.0452563 1.25935 0.0930136C0.368663 1.14979 0.0927854 4.01395 0.551924 5.44801C0.703657 5.92406 0.747009 6.42775 0.656363 6.9196C0.567689 7.40552 0.477043 7.99415 0.477043 8.40106C0.477043 12.5965 3.8723 16 8.05776 16C12.2452 16 15.6385 12.5985 15.6385 8.40106C15.6385 7.99218 15.5498 7.40552 15.4592 6.9196C15.3685 6.42775 15.4118 5.92406 15.5636 5.44801ZM8.05776 14.3012C4.82211 14.3012 2.18946 11.6623 2.18946 8.41884C2.18946 8.31218 2.19339 8.20748 2.19734 8.10279C2.31557 5.89245 3.65357 4.0021 5.54925 3.10137C6.30988 2.73792 7.16116 2.53644 8.05776 2.53644C8.95436 2.53644 9.80565 2.7399 10.5682 3.10335C12.4639 4.00408 13.8019 5.89443 13.9201 8.10279C13.9261 8.20748 13.928 8.31218 13.928 8.41884C13.9261 11.6623 11.2934 14.3012 8.05776 14.3012Z"
        fill="black"
      />
      <path
        d="M10.5999 10.6825L9.10029 8.07707L7.81351 5.90426C7.78395 5.8529 7.72878 5.82129 7.6677 5.82129H6.43807C6.37698 5.82129 6.32181 5.85488 6.29225 5.90623C6.26269 5.95957 6.26269 6.02475 6.29422 6.07611L7.52385 8.15015L5.87252 10.6746C5.83902 10.7259 5.83508 10.7931 5.86464 10.8484C5.8942 10.9037 5.95134 10.9373 6.01243 10.9373H7.24994C7.30708 10.9373 7.36029 10.9077 7.39182 10.8602L8.28448 9.46175L9.08452 10.8523C9.11408 10.9057 9.17123 10.9373 9.23034 10.9373H10.4501C10.5112 10.9373 10.5663 10.9057 10.5959 10.8523C10.6314 10.799 10.6314 10.7358 10.5999 10.6825Z"
        fill="black"
      />
    </svg>
  );
}
