import { Box, Flex, Stack } from '@chakra-ui/react';
import ContactBookIcon from 'remixicon-react/ContactsBook2FillIcon';
import { MegaMenuPopover } from './popover';
import { SectionTitle } from './section-title';
import { NavItemWithIcon } from './nav-item-with-icon';
import { NavItemTextOnly } from './nav-item-text-only';

const getStarted = [
  {
    label: 'NextJS',
    href: '#'
  },
  {
    label: 'Remix',
    href: '#'
  },
  {
    label: 'React',
    href: '#'
  },
  {
    label: 'See All',
    href: '#'
  }
];

const tutorials = [
  {
    label: 'Supabase & NextJS',
    href: '#'
  },
  {
    label: 'Hasura & NextJS',
    href: '#'
  },
  {
    label: 'Fauna & Remix',
    href: '#'
  },
  {
    label: 'See All',
    href: '#'
  }
];

const learn = [
  {
    label: 'Public Roadmap',
    href: '#'
  },
  {
    label: 'Changelog',
    href: '#'
  },
  {
    label: 'Support',
    href: '#'
  }
];

const api = [
  {
    label: 'Full API reference',
    href: '#'
  },
  {
    label: 'API Sattus',
    href: '#'
  }
];

export function Developers(): JSX.Element {
  return (
    <MegaMenuPopover label="Developers">
      <Flex gap={6} w="760px">
        <Stack flex={1} spacing={7}>
          <NavItemWithIcon
            label="Documentation"
            description="Start integrating Clerk’s products and tools."
            icon={ContactBookIcon}
            iconBoxBg="radial-gradient(127.5% 127.5% at 121.25% 0%, #9C82FF 0%, rgba(156, 130, 255, 0) 100%), radial-gradient(123.75% 123.75% at -10% 115%, rgba(91, 197, 239, 0.4) 0%, rgba(91, 197, 239, 0) 100%), #0C0129;"
            href="#"
            iconColor="gray.100"
          />

          <Flex>
            <Box flex={1}>
              <SectionTitle>Get Started</SectionTitle>

              <Stack px={3} spacing={0}>
                {getStarted.map(({ label, href }) => (
                  <NavItemTextOnly key={label} label={label} href={href} />
                ))}
              </Stack>
            </Box>

            <Box flex={1}>
              <SectionTitle>Tutorials</SectionTitle>

              <Stack px={3} spacing={0}>
                {tutorials.map(({ label, href }) => (
                  <NavItemTextOnly key={label} label={label} href={href} />
                ))}
              </Stack>
            </Box>
          </Flex>
        </Stack>

        <Flex bg="gray.25" flex={1} rounded="lg" p={3}>
          <Box flex={1}>
            <SectionTitle>Learn</SectionTitle>

            <Stack px={3} spacing={0}>
              {learn.map(({ label, href }) => (
                <NavItemTextOnly key={label} label={label} href={href} />
              ))}
            </Stack>
          </Box>

          <Box flex={1}>
            <SectionTitle>API</SectionTitle>

            <Stack px={3} spacing={0}>
              {api.map(({ label, href }) => (
                <NavItemTextOnly key={label} label={label} href={href} />
              ))}
            </Stack>
          </Box>
        </Flex>
      </Flex>
    </MegaMenuPopover>
  );
}
