import React from 'react';

type UseToggleIntercom = {
  shouldBeVisible: boolean;
};

export function useToggleIntercom({
  shouldBeVisible
}: UseToggleIntercom): void {
  React.useEffect(() => {
    const intercom = (window as any).Intercom;

    if (intercom) {
      if (shouldBeVisible) {
        intercom('update', {
          hide_default_launcher: false
        });
      } else {
        intercom('update', {
          hide_default_launcher: true
        });
      }
    }
  }, [shouldBeVisible]);
}
