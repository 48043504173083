export function SupabaseIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.38946 15.734C8.9807 16.2487 8.15191 15.9667 8.14207 15.3094L7.99805 5.69629H14.462C15.6328 5.69629 16.2858 7.04855 15.5578 7.96547L9.38946 15.734Z"
        fill="url(#desktop_paint0_linear_20783_31636)"
      />
      <path
        d="M9.38946 15.734C8.9807 16.2487 8.15191 15.9667 8.14207 15.3094L7.99805 5.69629H14.462C15.6328 5.69629 16.2858 7.04855 15.5578 7.96547L9.38946 15.734Z"
        fill="url(#desktop_paint1_linear_20783_31636)"
        fillOpacity="0.2"
      />
      <path
        d="M6.76051 0.265853C7.16927 -0.248943 7.99807 0.033128 8.00792 0.690391L8.07103 10.3035H1.68793C0.517094 10.3035 -0.135904 8.95124 0.592159 8.03432L6.76051 0.265853Z"
        fill="#3ECF8E"
      />
      <defs>
        <linearGradient
          id="desktop_paint0_linear_20783_31636"
          x1="7.99805"
          y1="7.82776"
          x2="13.743"
          y2="10.2372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#249361" />
          <stop offset="1" stopColor="#3ECF8E" />
        </linearGradient>
        <linearGradient
          id="desktop_paint1_linear_20783_31636"
          x1="5.45103"
          y1="4.34051"
          x2="8.07095"
          y2="9.27248"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
