import {
  ButtonGroup,
  Center,
  Divider,
  Stack,
  Text,
  Box,
  HStack,
  VisuallyHidden,
  Flex,
  Heading,
  useBreakpointValue,
  Grid,
  GridItem
} from '@chakra-ui/react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import * as React from 'react';
import { URLS, SECTION_IDS } from '@lib';
import {
  DiscordIcon,
  FacebookIcon,
  GitHubIcon,
  LinkedInIcon,
  TwitterIcon
} from '../common/icons';
import { LayoutContainer } from '@components';
import { analytics, rudderTrack } from 'pages';
import { useRouter } from 'next/router';

const {
  home,
  blog,
  pricing,
  company,
  dashboard,
  careers,
  docs,
  support,
  terms,
  privacy,
  facebook,
  twitter,
  linkedin,
  github,
  discord,
  apiStatus,
  mediaKit,
  roadmap
} = URLS;

const { homeFeatures } = SECTION_IDS;

type FooterNavigationKey = 'product' | 'developers' | 'company' | 'legal';

const footerNavigation: {
  [key in FooterNavigationKey]: Array<{
    name: string;
    href: string;
  }>;
} = {
  product: [
    { name: 'Features', href: `${home}#${homeFeatures}` },
    { name: 'Pricing', href: pricing },
    { name: 'Dashboard', href: dashboard },
    { name: 'Roadmap', href: roadmap }
  ],
  developers: [
    { name: 'Documentation', href: docs },
    { name: 'Discord server', href: discord },
    { name: 'Support', href: support },
    { name: 'API status', href: apiStatus }
  ],
  company: [
    { name: 'About', href: company },
    { name: 'Careers', href: careers },
    { name: 'Blog', href: blog },
    { name: 'Media kit', href: mediaKit }
  ],
  legal: [
    { name: 'Terms', href: terms },
    { name: 'Privacy', href: privacy }
  ]
};

type IconProps = {
  height: string;
  width: string;
  'aria-hidden': string;
};

const social = [
  {
    name: 'Twitter',
    href: twitter,
    icon: (props: IconProps) => <TwitterIcon {...props} />
  },
  {
    name: 'LinkedIn',
    href: linkedin,
    icon: (props: IconProps) => (
      <LinkedInIcon {...props} height="20px" width="20px" />
    )
  },
  {
    name: 'GitHub',
    href: github,
    icon: (props: IconProps) => <GitHubIcon {...props} />
  },
  {
    name: 'Discord',
    href: discord,
    icon: (props: IconProps) => <DiscordIcon {...props} />
  },
  {
    name: 'Facebook',
    href: facebook,
    icon: (props: IconProps) => <FacebookIcon {...props} />
  }
] as const;

function Copyright(): JSX.Element {
  return (
    <Text textStyle="paragraph-sm" color="gray.500">
      &copy; {new Date().getFullYear()} Clerk Inc.
    </Text>
  );
}

export function Footer(): JSX.Element {
  const dividerOrientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'horizontal',
    lg: 'vertical'
  });
  const { pathname } = useRouter();

  const whiteFooters = ['/pricing', '/components', '/customers'];

  return (
    <LayoutContainer
      as="footer"
      role="contentinfo"
      bg={whiteFooters.includes(pathname) ? 'white' : 'none'}
      verticalPadding="none"
    >
      <Stack
        spacing={{ lg: '8' }}
        justifyContent="center"
        py={{ base: '0', lg: '16' }}
      >
        <Flex direction={{ base: 'column', lg: 'row' }}>
          <Flex>
            <Stack spacing={{ base: '6', lg: '8' }} align="start">
              <HStack marginBottom={3}>
                <Image
                  src="/images/footer-logo.svg"
                  alt="Clerk logo"
                  width={32}
                  height={32}
                />

                <Text textStyle="label-sm" color="gray.700" pl="24px">
                  Clerk - Complete User Management
                </Text>
              </HStack>

              <ButtonGroup
                variant="ghost"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginTop={4}
                minWidth={56}
              >
                {social.map(item => (
                  <Box
                    as="a"
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    rel="noopener"
                    color="gray.400"
                    transition="color .2s"
                    _hover={{ color: 'gray.700' }}
                  >
                    <VisuallyHidden>{item.name}</VisuallyHidden>
                    {item.icon({
                      height: '22px',
                      width: '22px',
                      'aria-hidden': 'true'
                    })}
                  </Box>
                ))}
              </ButtonGroup>

              <Box pt="8" pb="12" display={{ base: 'none', lg: 'block' }}>
                <Copyright />
              </Box>
            </Stack>
          </Flex>

          <Center flex={1} py={{ base: 12, lg: 0 }} px={{ lg: 5 }}>
            <Divider orientation={dividerOrientation} />
          </Center>

          <Grid
            templateColumns={{
              base: 'repeat(2, min-content)',
              md: 'repeat(4, 1fr)'
            }}
            gap={12}
            overflowX="hidden"
          >
            {Object.keys(footerNavigation).map(item => {
              return (
                <GridItem
                  minW={{ base: 32, sm: 36, lg: 28, xl: 40 }}
                  key={item}
                >
                  <Heading
                    size="sm"
                    as="span"
                    display="block"
                    color="gray.500"
                    paddingBottom={8}
                    whiteSpace="nowrap"
                  >
                    {item}
                  </Heading>

                  <Stack spacing={4} shouldWrapChildren>
                    {(footerNavigation as any)[item].map((el: any) => (
                      <Box key={el.name}>
                        <Link legacyBehavior href={el.href} passHref>
                          <Text
                            as="a"
                            color="gray.700"
                            textStyle="label-sm"
                            cursor="pointer"
                            whiteSpace="nowrap"
                            _hover={{
                              color: 'primary.500'
                            }}
                          >
                            {el.name}
                          </Text>
                        </Link>
                      </Box>
                    ))}
                  </Stack>
                </GridItem>
              );
            })}
          </Grid>
        </Flex>

        <Center pt="8" pb="12" display={{ base: 'flex', lg: 'none' }} mx="auto">
          <Copyright />
        </Center>
      </Stack>

      <Divider />
    </LayoutContainer>
  );
}
