import Image from 'next/image';
import Link from 'next/link';
import {
  AspectRatio,
  Box,
  Center,
  Heading,
  Text,
  Button
} from '@chakra-ui/react';
import { URLS } from '@lib';

const Buttons = () => {
  const {
    javascript,
    quickstartsReact,
    quickstartsNext,
    quickstartsRemix,
    quickstartsGatsby,
    quickstartsRedwood,
    quickstartExpo,
    nodeDocs,
    rubyDocs,
    goDocs,
    supabaseDocs,
    firebase,
    faunaDocs,
    hasura
  } = URLS;

  return (
    <>
      <Link legacyBehavior href={javascript} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="54%"
          left={'15%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + JavaScript
        </Button>
      </Link>
      <Link legacyBehavior href={quickstartsReact} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="67%"
          left={'15%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + React
        </Button>
      </Link>
      <Link legacyBehavior href={quickstartsNext} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="81%"
          left={'15%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Next.js
        </Button>
      </Link>
      <Link legacyBehavior href={quickstartsRemix} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="60%"
          left={'26%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Remix
        </Button>
      </Link>
      <Link legacyBehavior href={quickstartsGatsby} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="73%"
          left={'26%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Gatsby
        </Button>
      </Link>
      <Link legacyBehavior href={quickstartsRedwood} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="60%"
          left={'36.5%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Redwood
        </Button>
      </Link>
      <Link legacyBehavior href={quickstartExpo} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="73%"
          left={'36.5%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Expo
        </Button>
      </Link>
      <Link legacyBehavior href={nodeDocs} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="60%"
          right={'36.5%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Node
        </Button>
      </Link>
      <Link legacyBehavior href={rubyDocs} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="73%"
          right={'36.5%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Ruby
        </Button>
      </Link>
      <Link legacyBehavior href={goDocs} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="60%"
          right={'26%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Go
        </Button>
      </Link>
      <Link legacyBehavior href={supabaseDocs} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="73%"
          right={'26%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Supabase
        </Button>
      </Link>
      <Link legacyBehavior href={firebase} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="54%"
          right={'15%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Firebase
        </Button>
      </Link>
      <Link legacyBehavior href={faunaDocs} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="67%"
          right={'15%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Fauna
        </Button>
      </Link>
      <Link legacyBehavior href={hasura} passHref>
        <Button
          as="a"
          opacity={0}
          pos="absolute"
          top="81%"
          right={'15%'}
          h="50px"
          w="50px"
          display={{ base: 'none', lg: 'inline-flex' }}
        >
          Docs for Clerk + Hasura
        </Button>
      </Link>
    </>
  );
};

export function ModernWeb(): JSX.Element {
  return (
    <Box pos="relative">
      <Center
        flexDir="column"
        pos="relative"
        zIndex={1}
        px={{ base: 6, lg: 8 }}
        textAlign="center"
      >
        <Box mb={10}>
          <Image
            src="/images/home/source-of-truth.svg"
            alt=""
            height="56"
            width="96"
          />
        </Box>

        <Heading as="h3" size="md" color="green.300" mb={2}>
          Built for the Modern Web
        </Heading>

        <Heading as="h3" size="h3" color="white" mb={4}>
          The most powerful APIs<br></br> built specifically for the Modern Web
        </Heading>

        <Text
          textStyle="paragraph-md"
          color="dark-text"
          flex={1}
          maxW="656px"
          textAlign="center"
        >
          New technologies have made building on the web much easier; however,
          many dev tools haven’t kept up. At Clerk, we believe a component is
          worth 1,000 APIs and that they should work seamlessly in any stack so
          that you can build faster.
        </Text>

        <AspectRatio w="100%" ratio={1680 / 590}>
          <Image src="/images/home/frameworks.png" alt="" fill />
        </AspectRatio>
        <Buttons />
        <AspectRatio
          pos="absolute"
          top={'300px'}
          ratio={1680 / 590}
          zIndex={-1}
          w="100%"
        >
          <Image src="/images/diamond-grid-dark.svg" alt="" fill />
        </AspectRatio>
      </Center>
    </Box>
  );
}
