import React from 'react';
import { Box } from '@chakra-ui/react';
import internal from 'stream';

type Props = {
  bg: string;
  canvasHeight: number;
  xPosition: 'left' | 'right';
  yPosition: 'top' | 'bottom';
  flip?: boolean;
};

export function RoundedCorner({
  bg,
  canvasHeight,
  xPosition,
  yPosition,
  flip
}: Props): JSX.Element {
  const arcRadius = canvasHeight / 2;
  const canvasWidth = arcRadius * 2;

  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const transform = [];

  // flip the shape depending on position
  if (xPosition === 'right') {
    transform.push('scaleX(-1)');
  }

  if (flip) {
    transform.push('translateY(-101%) scaleY(-1)');
  }

  if (yPosition === 'bottom') {
    transform.push('translateY(100%) scaleY(-1)');
  } else {
    transform.push('translateY(-100%)');
  }

  React.useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current?.getContext(
        '2d'
      ) as CanvasRenderingContext2D;

      ctx.beginPath();
      ctx.fillStyle = bg;

      // top left to top right
      ctx.moveTo(0, 0);
      ctx.arc(
        canvasWidth - 2 * arcRadius,
        arcRadius,
        arcRadius,
        1.5 * Math.PI, // 12pm
        0 * Math.PI // 3pm
      );

      // top right to bottom right
      ctx.lineTo(canvasWidth - arcRadius, canvasHeight - 2 * arcRadius);
      ctx.arc(
        canvasWidth,
        canvasHeight - arcRadius,
        arcRadius,
        1 * Math.PI, // 9pm
        0.5 * Math.PI, // 6pm
        true
      );

      // bottom right to bottom left
      ctx.lineTo(0, canvasHeight);
      ctx.fill();
    }
  }, [bg]);

  return (
    <Box
      position="absolute"
      top={yPosition === 'top' ? 0 : undefined}
      bottom={yPosition === 'bottom' ? 0 : undefined}
      left={xPosition === 'left' ? 0 : undefined}
      right={xPosition === 'right' ? 0 : undefined}
      transform={transform.join(' ')}
      display={{ base: 'none', md: 'block' }}
    >
      <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
    </Box>
  );
}
