import { Box } from '@chakra-ui/react';
import RocketIcon from 'remixicon-react/Rocket2FillIcon';
import BuildingIcon from 'remixicon-react/Building4FillIcon';
import SmileIcon from 'remixicon-react/UserSmileFillIcon';
import { MegaMenuPopover } from './popover';
import { NavItemWithIcon } from './nav-item-with-icon';

const links = [
  {
    label: 'For Startups',
    description: 'Launch quickly, with up to 6 months free.',
    icon: RocketIcon,
    href: '#'
  },
  {
    label: 'For Enterprises',
    description: 'Unrivaled features and support.',
    icon: BuildingIcon,
    href: '#'
  },
  {
    label: 'Customer Stories',
    description: 'Learn about the ways our customers use Clerk',
    icon: SmileIcon,
    href: '#'
  }
];

export function Customers(): JSX.Element {
  return (
    <MegaMenuPopover label="Customers">
      <Box w="420px">
        {links.map(({ label, description, icon, href }) => (
          <NavItemWithIcon
            key={label}
            label={label}
            description={description}
            icon={icon}
            href={href}
          />
        ))}
      </Box>
    </MegaMenuPopover>
  );
}
