export function LoginIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40003 2.3999C2.84185 2.3999 3.20003 2.75807 3.20003 3.1999L3.20003 12.7999C3.20003 13.2417 2.84185 13.5999 2.40002 13.5999C1.9582 13.5999 1.60002 13.2417 1.60002 12.7999L1.60003 3.1999C1.60003 2.75807 1.9582 2.3999 2.40003 2.3999ZM8.56571 5.03422C8.87813 5.34664 8.87813 5.85317 8.56571 6.16559L7.5314 7.1999L13.6 7.1999C14.0419 7.1999 14.4 7.55807 14.4 7.9999C14.4 8.44173 14.0419 8.7999 13.6 8.7999L7.5314 8.7999L8.56571 9.83422C8.87813 10.1466 8.87813 10.6532 8.56571 10.9656C8.25329 11.278 7.74676 11.278 7.43434 10.9656L5.03434 8.56559C4.88431 8.41556 4.80002 8.21207 4.80002 7.9999C4.80002 7.78773 4.88431 7.58425 5.03434 7.43422L7.43434 5.03422C7.74676 4.7218 8.25329 4.7218 8.56571 5.03422Z"
        fill="currentColor"
        transform="scale(-1,1) translate(-16,0)"
      />
    </svg>
  );
}
