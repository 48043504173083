export function ShieldPlusIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C7.91528 3.86587 5.17437 5.01363 2.16611 5.05445C2.05686 5.70522 2 6.37376 2 7.05557C2 12.2804 5.33923 16.7254 10 18.3727C14.6608 16.7254 18 12.2804 18 7.05557C18 6.37376 17.9431 5.70522 17.8339 5.05445C14.8256 5.01363 12.0847 3.86587 10 2ZM10 7C10.5523 7 11 7.44772 11 8V9H12C12.5523 9 13 9.44772 13 10C13 10.5523 12.5523 11 12 11H11V12C11 12.5523 10.5523 13 10 13C9.44772 13 9 12.5523 9 12V11H8C7.44771 11 7 10.5523 7 10C7 9.44771 7.44772 9 8 9H9V8C9 7.44772 9.44772 7 10 7Z"
        fill="currentColor"
      />
    </svg>
  );
}
