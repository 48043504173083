import React from 'react';
import NextLink from 'next/link';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { SignedIn, SignedOut } from '@clerk/nextjs';
import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  useDisclosure,
  VisuallyHidden
} from '@chakra-ui/react';
import { PURPLE_BG_PAGES, URLS } from '@lib';
import MenuIcon from 'remixicon-react/Menu3FillIcon';
import { openPopupWidget } from 'react-calendly';
import { LayoutContainer } from '../../common/layout-container';
import { MegaMenu } from './mega-menu';
import { MobileMenu } from './mobile-menu';

const { home } = URLS;
const DASHBOARD_URL = process.env.NEXT_PUBLIC_DASHBOARD_URL || '';

// Ensure the logo and CTAs have same width, so that `MegaMenu` is centred.
const LOGO_AND_CTAS_WIDTH = '260px';
export const HEADER_HEIGHT = '80px';

export function Header(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const { pathname } = useRouter();

  const hasPurpleBg = PURPLE_BG_PAGES.includes(pathname);
  const logo = hasPurpleBg
    ? '/images/clerk-logo-dark.svg'
    : '/images/clerk-logo.svg';
  const bg = hasPurpleBg ? 'background' : 'gray.25';
  const textColor = hasPurpleBg ? 'white' : 'gray.700';

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const onScroll = () => {
        const scrollY = window.scrollY;

        if (scrollY > 50 && !hasScrolled) {
          setHasScrolled(true);
        } else if (scrollY <= 50 && hasScrolled) {
          setHasScrolled(false);
        }
      };

      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [hasScrolled]);

  return (
    <LayoutContainer
      verticalPadding="none"
      pos="fixed"
      bg={bg}
      top={0}
      w="full"
      borderBottom={hasScrolled ? '1px solid' : 'none'}
      borderColor="gray.200"
      shadow={hasScrolled ? 'base' : 'none'}
      transition="all 0.2s"
      zIndex="sticky"
    >
      <Flex
        as="header"
        justify="space-between"
        align="center"
        gap={6}
        h={HEADER_HEIGHT}
      >
        <NextLink legacyBehavior href={home} passHref>
          <Box w={LOGO_AND_CTAS_WIDTH}>
            <Link href={home}>
              <VisuallyHidden>Clerk</VisuallyHidden>
              <Image
                src={logo}
                height="24"
                width="76"
                alt="Clerk logo"
                priority
              />
            </Link>
          </Box>
        </NextLink>

        {/* Mobile */}
        <Box display={{ lg: 'none' }}>
          <Button aria-label="menu" variant="unstyled" _hover={{}}>
            <Icon
              as={MenuIcon}
              boxSize={6}
              color={textColor}
              onClick={onOpen}
            />
          </Button>
          <MobileMenu isOpen={isOpen} onClose={onClose} />
        </Box>

        {/* Desktop */}
        <>
          <MegaMenu />

          <Flex
            justify="flex-end"
            gap={5}
            w={LOGO_AND_CTAS_WIDTH}
            display={{ base: 'none', lg: 'flex' }}
          >
            <SignedOut>
              {hasScrolled ? (
                <Button
                  variant="ghost"
                  onClick={() => {
                    openPopupWidget({
                      url: URLS.calendlyUrl
                    });
                  }}
                >
                  Book a demo
                </Button>
              ) : (
                <NextLink
                  legacyBehavior
                  href={DASHBOARD_URL + '/sign-in'}
                  passHref
                >
                  <Button as="a" variant="ghost" color={textColor} _hover={{}}>
                    Sign in
                  </Button>
                </NextLink>
              )}

              <NextLink
                legacyBehavior
                href={DASHBOARD_URL + '/sign-up'}
                passHref
              >
                <Button as="a" variant="white">
                  Sign up
                </Button>
              </NextLink>
            </SignedOut>

            <SignedIn>
              <NextLink legacyBehavior href={DASHBOARD_URL} passHref>
                <Button as="a">Dashboard</Button>
              </NextLink>
            </SignedIn>
          </Flex>
        </>
      </Flex>
    </LayoutContainer>
  );
}
