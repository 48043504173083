import { Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

export default function Click({ Button, popup }) {
  const [openState, setOpenState] = useState(false);
  return (
    <div className="relative lg:hidden">
      <div onClick={() => setOpenState(!openState)} className="w-full">
        <Button open={openState} />
        <Transition
          show={openState}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          {popup}
        </Transition>
      </div>
    </div>
  );
}
