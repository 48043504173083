import { Box } from '@chakra-ui/react';
import Image from 'next/image';
import { ThemeProps } from './customization';

interface ThemeBGLoaderProps {
  currentTheme: ThemeProps;
}

export const ThemeBGLoader = ({ currentTheme }: ThemeBGLoaderProps) => {
  return (
    <>
      <Box display={currentTheme?.name == 'Light' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/desktop/browser-background.svg`}
          alt={currentTheme?.name}
          fill
          priority
        />
      </Box>
      <Box display={currentTheme?.name == 'Dark' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/desktop/dark-bg.svg`}
          alt={currentTheme?.name}
          fill
          priority
        />
      </Box>
      <Box display={currentTheme?.name == 'Simple' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/desktop/simple-bg.svg`}
          alt={currentTheme?.name}
          fill
          priority
        />
      </Box>
      <Box display={currentTheme?.name == 'Wave' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/desktop/wave-bg.svg`}
          alt={currentTheme?.name}
          fill
          priority
        />
      </Box>
      <Box display={currentTheme?.name == 'Abstract' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/desktop/abstract-bg.svg`}
          alt={currentTheme?.name}
          fill
          priority
        />
      </Box>
      <Box display={currentTheme?.name == 'Retro' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/desktop/retro-bg.svg`}
          alt={currentTheme?.name}
          fill
          priority
        />
      </Box>
      <Box display={currentTheme?.name == 'Neobrutalism' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/desktop/neobrutalism-bg.svg`}
          alt={currentTheme?.name}
          fill
          priority
        />
      </Box>
      <Box display={currentTheme?.name == 'Yolo' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/desktop/yolo-bg.svg`}
          alt={currentTheme?.name}
          fill
          priority
        />
      </Box>
    </>
  );
};
