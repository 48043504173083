import React from 'react';
import { useUser } from '@clerk/nextjs';

export function useKoalaIdentify() {
  if (typeof window !== 'undefined') {
    const { user } = useUser();
    React.useEffect(() => {
      if (user && window.ko && !window.ko.email) {
        window.ko.identify({
          email: user.primaryEmailAddress?.emailAddress,
          name: user.fullName
        });
      }
    }, [user]);
  }

  return;
}
