import { Box, Flex, Stack } from '@chakra-ui/react';
import GroupIcon from 'remixicon-react/Group2FillIcon';
import ServiceIcon from 'remixicon-react/ServiceFillIcon';
import EmpathizeIcon from 'remixicon-react/EmpathizeFillIcon';
import TerminalIcon from 'remixicon-react/TerminalWindowFillIcon';
import GlobalIcon from 'remixicon-react/GlobalFillIcon';
import KeyIcon from 'remixicon-react/Key2FillIcon';
import ShieldCrossIcon from 'remixicon-react/ShieldCrossFillIcon';
import ShieldCheckIcon from 'remixicon-react/ShieldCheckFillIcon';
import CursorIcon from 'remixicon-react/CursorFillIcon';
import HandHearthIcon from 'remixicon-react/HandHeartFillIcon';
import PaintIcon from 'remixicon-react/PaintFillIcon';
import LoginIcon from 'remixicon-react/LoginBoxFillIcon';
import LayoutIcon from 'remixicon-react/Layout3FillIcon';
import LayoutColumnIcon from 'remixicon-react/LayoutColumnFillIcon';
import InboxIcon from 'remixicon-react/InboxArchiveFillIcon';
import ListSettingsIcon from 'remixicon-react/ListSettingsFillIcon';
import ArrowRightIcon from 'remixicon-react/ArrowRightLineIcon';
import { MegaMenuPopover } from './popover';
import { SectionTitle } from './section-title';
import { NavItemWithIcon } from './nav-item-with-icon';

const clerkFor = [
  {
    label: 'B2C Apps',
    description: 'High converting, customizable, and simple authentication.',
    icon: GroupIcon,
    href: '#'
  },
  {
    label: 'B2B SaaS Apps',
    description: 'Organizations, RBAC, SAML, and more.',
    icon: ServiceIcon,
    href: '#'
  },
  {
    label: 'Fin-Tech & Healt-Tech',
    description: 'Enhanced security and compliance for your users.',
    icon: EmpathizeIcon,
    href: '#'
  },
  {
    label: 'Internal Tool',
    description: 'Easily add auth to your internal tools.',
    icon: TerminalIcon,
    href: '#'
  },
  {
    label: 'Web3 Apps',
    description: 'Web2 bridge, wallets, and more.',
    icon: GlobalIcon,
    href: '#'
  }
];

const prebuiltComponents = [
  {
    label: 'Powerful Customization',
    description: 'The most powerful and intuitive customization library.',
    icon: PaintIcon,
    href: '#'
  },
  {
    label: 'Sign Up & Sign In',
    description: 'High converting modals and flows.',
    icon: LoginIcon,
    href: '#'
  },
  {
    label: 'Users Components',
    description: 'User profile, Account security, User button, & more.',
    icon: LayoutIcon,
    href: '#'
  },
  {
    label: 'Organizations Components',
    description: 'RBAC, Invitations & SAML.',
    icon: LayoutColumnIcon,
    href: '#'
  }
];

const features = [
  {
    label: 'Standard Authentication',
    description: 'Emails, phones, magic links, OTPs, social logins, etc.',
    icon: KeyIcon,
    href: '#'
  },
  {
    label: 'Multifactor Authentication',
    description: 'Enhanced security with SMS and TOTP.',
    icon: ShieldCrossIcon,
    href: '#'
  },
  {
    label: 'Session Management',
    description: 'Revocable sessions and short lived JWTs.',
    icon: ShieldCheckIcon,
    href: '#'
  },
  {
    label: 'User & Org Management',
    description: 'Control your customers via the Clerk dashboard.',
    icon: CursorIcon,
    href: '#'
  },
  {
    label: 'User Impersonation',
    description: 'Sign in as your users for support and debugging.',
    icon: HandHearthIcon,
    href: '#'
  },
  {
    label: 'RBAC & Invitiatons',
    description: 'Add roles, permissions & more.',
    icon: HandHearthIcon,
    href: '#'
  }
];

const prebuiltComponents2 = [
  {
    label: 'SDKs',
    description: 'Explore all of our SDKs.',
    icon: InboxIcon,
    href: '#'
  },
  {
    label: 'Integrations',
    description: 'Explore all of our integrations.',
    icon: ListSettingsIcon,
    href: '#'
  }
];

export function Product(): JSX.Element {
  return (
    <MegaMenuPopover label="Product">
      <Flex gap={20} w="950px">
        <Stack spacing={2}>
          <Box mb={3}>
            <SectionTitle>Clerk for...</SectionTitle>

            {clerkFor.map(({ label, description, icon, href }) => (
              <NavItemWithIcon
                key={label}
                label={label}
                description={description}
                icon={icon}
                href={href}
              />
            ))}
          </Box>

          <Box>
            <SectionTitle>Pre-built components</SectionTitle>

            {prebuiltComponents.map(({ label, description, icon, href }) => (
              <NavItemWithIcon
                key={label}
                label={label}
                description={description}
                icon={icon}
                href={href}
              />
            ))}
          </Box>
        </Stack>
        <Box>
          <Box mb={3}>
            <SectionTitle>Features</SectionTitle>

            {features.map(({ label, description, icon, href }) => (
              <NavItemWithIcon
                key={label}
                label={label}
                description={description}
                icon={icon}
                href={href}
              />
            ))}

            <NavItemWithIcon
              label="And much more..."
              description="Explore all of Clerk’s features."
              href="#"
              icon={ArrowRightIcon}
              iconBoxBg="white"
              iconColor="primary.500"
              iconSize={5}
            />
          </Box>

          <Box>
            <SectionTitle>Pre-built components</SectionTitle>

            {prebuiltComponents2.map(({ label, description, icon, href }) => (
              <NavItemWithIcon
                key={label}
                label={label}
                description={description}
                icon={icon}
                href={href}
              />
            ))}
          </Box>
        </Box>
      </Flex>
    </MegaMenuPopover>
  );
}
