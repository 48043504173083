import Link from 'next/link';
import { Box, Center, Flex, Icon, LayoutProps, Text } from '@chakra-ui/react';
import { RemixiconReactIconComponentType } from 'remixicon-react';

type Props = {
  label: string;
  description: string;
  icon: RemixiconReactIconComponentType;
  iconBoxBg?: string;
  iconColor?: string;
  iconSize?: LayoutProps['boxSize'];
  href: string;
};

export function NavItemWithIcon({
  label,
  description,
  icon,
  iconBoxBg,
  iconColor,
  iconSize,
  href
}: Props) {
  return (
    <Link legacyBehavior href={href} passHref>
      <Flex
        as="a"
        align="center"
        gap={4}
        px={3}
        py={2}
        rounded="10px"
        role="group"
        transition="background .2s"
        _hover={{
          bg: 'gray.25'
        }}
      >
        <Center
          boxSize={10}
          bg={iconBoxBg || 'gray.25'}
          rounded="md"
          transition="background .2s"
          _groupHover={{
            bg: !iconBoxBg && 'white',
            shadow: 'secondaryButtonNormal'
          }}
        >
          <Icon
            as={icon}
            boxSize={iconSize || 6}
            color={iconColor || 'gray.500'}
            _groupHover={{
              color: !iconColor && 'primary.500'
            }}
          />
        </Center>

        <Box>
          <Text textStyle="label-sm" color="gray.700">
            {label}
          </Text>
          <Text textStyle="paragraph-sm" color="gray.500">
            {description}
          </Text>
        </Box>
      </Flex>
    </Link>
  );
}
