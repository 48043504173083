import { ComponentDefaultProps } from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';

const baseStyle: Record<string, CSSObject> = {
  _hover: {
    borderColor: 'primary.500',
  },
  _focus: {
    borderColor: 'primary.500',
    boxShadow: 'none !important',
  },
  _focusVisible: {
    borderColor: 'primary.500',
    boxShadow: 'none !important',
  },
};

const defaultProps: ComponentDefaultProps = {
  size: 'md',
};

const sizes: Record<string, CSSObject> = {
  md: {
    fontSize: 'xs',
    fontWeight: 'normal',
    borderRadius: 'md',
  },
};

export const Textarea = {
  baseStyle,
  defaultProps,
  sizes,
};
