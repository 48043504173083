import { dark } from '@clerk/themes';

const darkTheme = {
  variables: {
    colorBackground: '#19191A',
    colorInputBackground: '#19191A',
    colorAlphaShade: 'white',
    colorText: 'white',
    colorInputText: 'white'
  },
  elements: {
    logoImage: {
      filter: 'brightness(0) invert(1)'
    },
    socialButtonsProviderIcon__github: {
      filter: 'brightness(0) invert(1)'
    },
    footer: {
      '& + div': {
        background: 'rgb(49, 49, 51)'
      }
    }
  }
};

const simple = {
  layout: {
    socialButtonsVariant: 'iconButton'
  },
  variables: {
    colorPrimary: '#000000'
  },
  elements: {
    card: {
      boxShadow: '10px',
      backgroundColor: 'white'
    },
    headerTitle: {
      fontFamily: 'Merriweather',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '30px',

      color: '#000000'
    },
    headerSubtitle: {
      fontFamily: '"Merriweather Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '20px',

      color: 'rgba(0, 0, 0, 0.65)'
    },
    logoBox: {
      display: 'flex',
      flexDirection: 'row',

      padding: 0,
      gap: '4px'
    },
    logoImage: {
      filter: 'brightness(0%)',
      width: '76.5px',
      height: '24px'
    },
    socialButtons: {
      backgroundColor: 'white'
    },
    formButtonPrimary: {
      background: '#000000',
      borderRadius: '6px',
      fontFamily: 'Merriweather',
      letterSpacing: '0.5px',
      color: '#FFFFFF',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px'
    },
    formFieldLabel: {
      padding: '0px 0px 4px',
      gap: '2px',
      fontFamily: 'Merriweather'
    },
    formFieldInput: {
      border: '1px solid #000000 !important',
      borderRadius: '6px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '10px 10px 10px 16px',
      gap: '8px'
    },
    footerActionLink: {
      width: '45px',
      height: '15px',
      fontFamily: 'Merriweather',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '15px',
      textDecorationLine: 'underline',
      color: '#000000'
    },
    footerActionText: {
      width: '71px',
      height: '15px',
      fontFamily: '"Merriweather Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '12px',
      lineHeight: '15px',
      color: 'rgba(0, 0, 0, 0.65)'
    }
  }
};

const wave = {
  variables: {
    colorPrimary: '#0099FF',
    borderRadius: '8px',
    fontFamily: 'Raleway, sans-serif',
    fontWeight: {
      normal: 400,
      medium: 500,
      bold: 600
    }
  },
  layout: {
    socialButtonsVariant: 'iconButton'
  },
  elements: {
    card: {
      borderRadius: '32px'
    },
    logoImage: {
      filter: 'hue-rotate(342deg) brightness(1.4)'
    },
    main: {
      gap: '1.5rem'
    },
    headerTitle: {
      fontSize: '24px'
    },
    headerSubtitle: {
      fontSize: '16px'
    },
    socialButtons: {
      display: 'flex'
    },
    socialButtonsIconButton: {
      borderRadius: '100%',
      padding: 18
    },
    dividerBox: {
      display: 'none'
    },
    formFieldInput: {
      borderRadius: '100px'
    },
    formButtonPrimary: {
      borderRadius: '100px',
      textTransform: 'none'
    },
    footerActionLink: {
      fontWeight: 500
    }
  }
};

const abstract = {
  baseTheme: dark,
  layout: {
    socialButtonsVariant: 'iconButton'
  },
  variables: {
    colorPrimary: '#FFFFFF',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif'
  },
  elements: {
    card: {
      background: 'linear-gradient(180deg, #39269B 0%, #342480 100%)'
    },
    logoImage: {
      filter: 'brightness(0) invert(1)'
    },
    headerTitle: {
      fontSize: '28px'
    },
    headerSubtitle: {
      color: '#FFFFFF'
    },
    main: {
      gap: '2rem'
    },
    socialButtonsProviderIcon__github: {
      filter: 'brightness(0) invert(1)'
    },
    dividerBox: {
      display: 'none'
    },
    formFieldInput: {
      backgroundColor: 'transparent'
    },
    formButtonPrimary: {
      backgroundColor: '#FFFFFF30',
      fontSize: '12px',
      textTransform: 'none',
      '&:focus': { backgroundColor: '#FFFFFF15' },
      '&:active': { backgroundColor: '#FFFFFF15' },
      '&:hover': { backgroundColor: '#FFFFFF15' }
    },
    footer: {
      '& + div': {
        backgroundColor: '#130162'
      }
    }
  }
};

const win98Shadow =
  'inset -1px -1px 0px #000000, inset 1px 1px 0px #FFFFFF, inset -2px -2px 0px #808080, inset 2px 2px 0px #DBDBDB';
const win98ShadowReverse =
  'inset -1px -1px 0px #dbdbdb, inset 1px 1px 0px #808080, inset -2px -2px 0px #000000, inset 2px 2px 0px #000000';

const retro = {
  layout: {
    socialButtonsVariant: 'iconButton'
  },
  variables: {
    colorPrimary: '#008080',
    colorBackground: '#C0C0C0',
    colorInputBackground: '#FFFFFF',
    borderRadius: 'none',
    fontFamily: 'var(--w95fa)',
    fontSize: '1.2rem'
  },
  elements: {
    card: {
      boxShadow: win98Shadow
    },
    formFieldInput: {
      border: '1px solid #000000 !important'
    },
    logoImage: {
      filter: 'hue-rotate(300deg)'
    },
    socialButtons: {
      display: 'flex'
    },
    socialButtonsIconButton: {
      padding: 15,
      boxShadow: win98Shadow,
      '&:focus': { boxShadow: win98ShadowReverse },
      '&:active': { boxShadow: win98ShadowReverse }
    },
    dividerBox: {
      display: 'none'
    },
    formButtonPrimary: {
      boxShadow: win98Shadow,
      backgroundColor: '#C0C0C0',
      color: '#000000',
      textTransform: 'none',
      '&:focus': { backgroundColor: '#C0C0C0', boxShadow: win98ShadowReverse },
      '&:active': { backgroundColor: '#C0C0C0', boxShadow: win98ShadowReverse },
      '&:hover': { backgroundColor: '#C0C0C0' }
    },
    footer: {
      '& + div': {
        color: '#000000',
        backgroundColor: '#C0C0C0',
        boxShadow: 'inset 1px 1px 0px #FFFFFF, inset -2px -2px 0px #808080'
      }
    }
  }
};

const neobrutalism = {
  variables: {
    colorPrimary: '#DF1B1B',
    colorTextSecondary: '#000',
    fontWeight: {
      normal: 400,
      medium: 600,
      bold: 700
    }
  },
  layout: {
    socialButtonsVariant: 'iconButton'
  },
  elements: {
    card: {
      boxShadow: '7px 7px 0px #000',
      border: '3px solid #000'
    },
    headerTitle: {
      fontSize: '24px'
    },
    headerSubtitle: {
      fontSize: '0.8125rem',
      fontWeight: 600
    },
    socialButtonsIconButton: {
      height: '2.5rem',
      boxShadow: '3px 3px 0px #000',
      borderRadius: '0.5rem',
      border: '2px solid #000',
      '&:focus': {
        boxShadow: '4px 4px 0px #000',
        border: '2px solid #000',
        transform: 'scale(1.01)'
      },
      '&:active': {
        boxShadow: '2px 2px 0px #000',
        transform: 'translate(1px)'
      }
    },
    dividerLine: {
      background: '#000'
    },
    formFieldInput: {
      boxShadow: '3px 3px 0px #000',
      border: '2px solid #000',
      transition: 'all 0.2s ease-in-out',
      padding: '0.6175rem 1rem',
      '&:focus': {
        boxShadow: '4px 4px 0px #000',
        border: '2px solid #000',
        transform: 'scale(1.01)'
      }
    },
    formButtonPrimary: {
      height: '2.5rem',
      border: '2px solid #000',
      boxShadow: '3px 3px 0px #000'
    },
    footer: {
      '& + div': {
        border: '2px solid #000',
        boxShadow: '-4px 1px 0 0 #000'
      }
    },
    footerActionLink: {
      fontWeight: 600,
      borderBottom: '2px solid',
      borderColor: '#DF1B1B',
      '&:focus': {
        boxShadow: 'none'
      },
      '&:hover': {
        textDecorationLine: 'none'
      }
    },
    footerActionText: {
      fontWeight: 600
    },
    logoImage: {
      filter: 'hue-rotate(140deg)'
    }
  }
};

const yolo = {
  layout: {
    socialButtonsVariant: 'iconButton'
  },
  variables: {
    colorBackground: '#FFC62A',
    colorPrimary: '#8C42F2',
    colorDanger: '#ff00dd',
    colorInputBackground: '#FFFFFF40',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif'
  },
  elements: {
    logoImage: {
      filter: 'hue-rotate(15deg)'
    },
    card: { boxShadow: '0px 24px 48px rgba(0, 0, 0, 0.32)' },
    headerTitle: {
      fontSize: '24px'
    },
    socialButtonsIconButton: {
      backgroundColor: '#FFFFFF40',
      borderColor: 'transparent'
    },
    formFieldInput: { borderColor: 'transparent' },
    formButtonPrimary: {
      fontSize: '14px',
      textTransform: 'none'
    }
  }
};

export const THEMES = {
  dark: darkTheme,
  simple,
  wave,
  abstract,
  retro,
  neobrutalism,
  yolo
};
