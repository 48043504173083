import { Box } from '@chakra-ui/react';
import { Footer } from '@components/layout';
import { Pricing, NewsLetter, CShapeBlurPattern } from '.';

export function BottomCTAV2(): JSX.Element {
  return (
    <Box pt={20} overflow="hidden" bg="white">
      <CShapeBlurPattern right={0} />
      <Pricing />
      <NewsLetter />
      <Footer />
    </Box>
  );
}
