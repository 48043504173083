export function ChevronIcon(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="#9CA3AF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79289 7.29289C6.18342 6.90237 6.81658 6.90237 7.2071 7.29289L10.5 10.5858L13.7929 7.29289C14.1834 6.90237 14.8166 6.90237 15.2071 7.29289C15.5976 7.68342 15.5976 8.31658 15.2071 8.70711L11.2071 12.7071C10.8166 13.0976 10.1834 13.0976 9.79289 12.7071L5.79289 8.70711C5.40237 8.31658 5.40237 7.68342 5.79289 7.29289Z"
      />
    </svg>
  );
}
