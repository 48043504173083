export function UserIcon(props) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM9.375 4.6875C9.375 5.72303 8.53553 6.5625 7.5 6.5625C6.46447 6.5625 5.625 5.72303 5.625 4.6875C5.625 3.65197 6.46447 2.8125 7.5 2.8125C8.53553 2.8125 9.375 3.65197 9.375 4.6875ZM7.49994 8.4375C5.60849 8.4375 3.9787 9.55777 3.23788 11.171C4.2694 12.3675 5.79624 13.125 7.49998 13.125C9.2037 13.125 10.7305 12.3676 11.762 11.1711C11.0212 9.5578 9.39141 8.4375 7.49994 8.4375Z"
        fill="currentColor"
      />
    </svg>
  );
}
