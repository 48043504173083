import Docsly from "@docsly/react";
import "@docsly/react/styles.css";
import { usePathname } from "next/navigation";

export default function DocslyClient() {
  const pathname = usePathname();
  if (!pathname) return null;
  const docslyPublicId = process.env.NEXT_PUBLIC_DOCSLY_ID;
  if (!docslyPublicId) throw new Error("NEXT_PUBLIC_DOCSLY_ID is not set");

  return <Docsly highlightToComment={false} publicId={docslyPublicId} pathname={pathname} />;
}
