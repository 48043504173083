import localFont from '@next/font/local';
import { Source_Code_Pro } from '@next/font/google';

const satoshi = localFont({
  src: '../../fonts/Satoshi-Variable.woff2',
  weight: '300 900'
});

const w95fa = localFont({
  src: '../../fonts/w95fa.woff'
});

const sourceCodePro = Source_Code_Pro({
  subsets: [
    'vietnamese',
    'latin-ext',
    'latin',
    'greek-ext',
    'greek',
    'cyrillic-ext',
    'cyrillic'
  ]
});

export function Fonts(): JSX.Element {
  return (
    <style jsx global>{`
      :root {
        --satoshi: ${satoshi.style.fontFamily};
        --w95fa: ${w95fa.style.fontFamily};
        --source-code-pro: ${sourceCodePro.style.fontFamily};
      }
    `}</style>
  );
}
